// src/TranscribePage.js

import React from "react";
import { useState, useEffect } from "react";
import TranscribeAudio from "../services/TranscribeAudio";
import NoteBox from "../modules/NoteBox";

import "../css/TranscribePage.css";

function TranscribePage() {
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        const wsUrl = process.env.NODE_ENV === 'production' 
            ? 'wss://api.v2notes.com:3001' // PROD URL here
            // : 'wss://api.v2notes.com:3001'
            : 'ws://localhost:3001'; // DEV URL
        const ws = new WebSocket(wsUrl);
        setSocket(ws);

        return () => ws.close();
    }, []);

    return (
        <div className="container">
            <h1 className="title">Live Voice to Text</h1>
            {socket && <NoteBox socket={socket} />}
            {socket && <TranscribeAudio socket={socket} />}
        </div>
    );
}

export default TranscribePage;
