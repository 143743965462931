import React, { useEffect, useState } from "react";
import "../css/Alert.css";

const Alert = ({ message, showAlert, onClose }) => {
    const [fade, setFade] = useState(false);

    useEffect(() => {
        if (showAlert) {
            setFade(false);
            const timer = setTimeout(() => {
                setFade(true); // Start fade out animation
                setTimeout(onClose, 400);
            }, 1200);
            return () => clearTimeout(timer);
        }
    }, [showAlert, onClose]);

    return showAlert ? (
        <div className={`alert-overlay ${fade ? "fade-out" : ""}`}>
            <div className="alert-box">
                <svg
                    className="checkmark"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 52 52"
                >
                    <circle
                        className="checkmark-circle"
                        cx="26"
                        cy="26"
                        r="25"
                        fill="none"
                    />
                    <path
                        className="checkmark-check"
                        fill="none"
                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                    />
                </svg>
                <p>{message}</p>
            </div>
        </div>
    ) : null;
};

export default Alert;
