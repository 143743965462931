import React, { useEffect, useState } from "react";
import "../css/Timer.css";

const Timer = ({ isActive, interval, onInterval }) => {
    const [seconds, setSeconds] = useState(0);

    // Converts total seconds into MM:SS format
    const formatTime = (totalSeconds) => {
        const minutes = Math.floor(totalSeconds / 60)
            .toString()
            .padStart(2, "0");
        const seconds = (totalSeconds % 60).toString().padStart(2, "0");
        return `${minutes}:${seconds}`;
    };

    useEffect(() => {
        let timerId;

        if (isActive) {
            timerId = setInterval(() => {
                setSeconds((prevSeconds) => {
                    const newSeconds = prevSeconds + 1;
                    if (newSeconds % interval === 0) {
                        onInterval();
                    }
                    return newSeconds;
                });
            }, 1000);
        }

        return () => clearInterval(timerId);
    }, [isActive, interval, onInterval]);

    return <div className="timer">{formatTime(seconds)}</div>;
};

export default Timer;
