import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import Alert from './Alert';

import '../css/NoteBox.css';

function NoteBox({ socket }) {
    const textAreaRef = useRef(null);
    const [transcription, setTranscription] = useState(() => {
        // Initialize state from localStorage if available
        return localStorage.getItem('transcription') || '';
    });
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        socket.onmessage = (event) => {
            const message = JSON.parse(event.data);
            console.log('Received message:', message);
            console.log('Transcription:', message.results)
            if (message.type === 'error') {
                setError(message.message); // Set the error state
            } else{
                const text = message.results[0]?.alternatives[0]?.transcript;
                if (text) {
                    const newText = `${textAreaRef.current.value}${text}`;
                    textAreaRef.current.value = newText;
                    setTranscription(newText);
                    localStorage.setItem('transcription', newText); // Save to localStorage
                }
            }
        };
        return () => {
            socket.onmessage = null;
        };
    }, [socket]);

    const handleUserInput = (e) => {
        setTranscription(e.target.value);
        localStorage.setItem('transcription', e.target.value); // Update localStorage
    };

    const handleReset = () => {
        setTranscription(''); // Reset transcription
        localStorage.removeItem('transcription'); // Clear the localStorage
        if (textAreaRef.current) {
            textAreaRef.current.value = ''; // Clear the textarea
        }
        setMessage('Reset');
        setShowAlert(true);
    };

    const handleCopy = () => {
        if (textAreaRef.current) {
            navigator.clipboard.writeText(textAreaRef.current.value); // Copy the text
        }
        setMessage('Copied to clipboard');
        setShowAlert(true);
    };

    return (
        <div className='notebox-container'>
            <textarea
                id='liveTranscriptionBox'
                ref={textAreaRef}
                value={transcription}
                placeholder='Transcription will appear here'
                onChange={handleUserInput} // Handle changes
                rows={10}
            />
            <div className='notebox-actions'>
                <button onClick={handleReset} className='notebox-btn'>
                    <FontAwesomeIcon icon={faArrowsRotate} />
                    <span> Reset</span>
                </button>
                <button onClick={handleCopy} className='notebox-btn'>
                    <FontAwesomeIcon icon={faCopy} />
                    <span> Copy</span>
                </button>
                <Alert
                    message={message}
                    showAlert={showAlert}
                    onClose={() => setShowAlert(false)}
                />
            </div>
        </div>
    );
}

export default NoteBox;
