import React, { useState } from "react";
import "../css/Navbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone, faComment, faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(true);

    const handleMenuItemClick = (page) => {
        // Implement page change logic here
        console.log(`Navigate to ${page}`);
    };

    return (
        <div className={`navbar ${isOpen ? "open" : "closed"}`}>
            <div className="logo-section">
                <img src="v2notes_logo_dark_trans.png" alt="Logo" />
            </div>
            <div className="menu-items">
                <div
                    className="menu-item"
                    onClick={() => handleMenuItemClick("live")}
                >
                    <FontAwesomeIcon className="icon" icon={faMicrophone} />
                    <span>Live</span>
                </div>
                {/* <div
                    className="menu-item"
                    onClick={() => handleMenuItemClick("clips")}
                >
                    <FontAwesomeIcon className="icon" icon={faComment} />
                    <span>Clips</span>
                </div> */}
            </div>
            <button
                className="toggle-button"
                onClick={() => setIsOpen(!isOpen)}
            >
                {isOpen ? <FontAwesomeIcon icon={faArrowLeft} /> : <FontAwesomeIcon icon={faArrowRight} />}
            </button>
        </div>
    );
};

export default Navbar;
