// TranscribeAudio.js
import React, { useState, useEffect, useCallback } from "react";
import Modal from "../modules/Modal";

import "../css/TranscribeAudio.css";

import Timer from "../modules/Timer";
const STREAM_RESET_INTERVAL = 420; // reset every 7 mins (420 secs) to avoid timeout

export default function TranscribeAudio({ socket }) {
    const [isRecording, setIsRecording] = useState(false);
    const [showModal, setShowModal] = useState(false);


    const resetStream = () => {
        console.log("Resetting stream");
        if (isRecording) {
            setIsRecording(false); // Stop the recording first
            socket.send("EOS"); // End the current stream
            setShowModal(true); // Show the confirmation modal
        }
    };

    // Function to handle "OK" button click in the modal
    const handleModalClose = () => {
        setShowModal(false); // Close the modal
        setIsRecording(true); // Resume recording
        socket.send("SOS"); // Start a new stream
    };

    const startRecording = useCallback(async () => {
        console.log("Starting recording");
        socket.send("SOS"); // Send start of stream message
        try {
            // Request user's microphone access
            const sampleRate = 16000;
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: {
                    deviceId: "default",
                    sampleRate: sampleRate,
                    sampleSize: 16,
                    channelCount: 1,
                },
                video: false,
            });
            const audioContext = new AudioContext({ sampleRate: sampleRate });
            const source = audioContext.createMediaStreamSource(stream);
            await audioContext.audioWorklet.addModule("audio-processor.js"); // addModule path starts in public dir
            const audioWorkletNode = new AudioWorkletNode(
                audioContext,
                "audio-processor",
                {
                    outputChannelCount: [1],
                }
            );

            source.connect(audioWorkletNode);

            // source.connect(audioContext.destination); // Direct playback through speakers for testing

            audioWorkletNode.port.onmessage = (event) => {
                if (isRecording) {
                    const audioData = event.data;
                    socket.send(audioData); // Send the audio data
                    audioWorkletNode.port.start();
                }
            };

            return () => {
                console.log("Stopping recording");
                socket.send("EOS"); // Send End of Stream message
                audioContext.close();
            };
        } catch (error) {
            console.error("Error accessing the microphone:", error);
        }
    }, [isRecording, socket]);

    useEffect(() => {
        let stopRecordingCleanup;

        if (isRecording) {
            startRecording().then((cleanup) => {
                stopRecordingCleanup = cleanup;
            });
        }

        return () => {
            if (stopRecordingCleanup) {
                stopRecordingCleanup();
            }
        };
    }, [isRecording, startRecording]);

    return (
        <div className="main-container">
            <button
                className={`button ${isRecording ? "recording" : ""}`}
                onClick={() => setIsRecording(!isRecording)}
            >
                {isRecording ? "Stop Recording" : "Start Recording"}
            </button>

            <Timer
                isActive={isRecording}
                interval={STREAM_RESET_INTERVAL}
                onInterval={() => resetStream()}
            />

            <Modal isOpen={showModal} onClose={handleModalClose}>
                <p>Are you still there?</p>
            </Modal>
        </div>
    );
}
