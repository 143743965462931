// App.js
import React from "react";
import Sidebar from "./modules/Navbar";    
import TranscribePage from "./pages/TranscribePage";

function App() {
    return (
        <div className="App">
            <Sidebar />
            <TranscribePage />
        </div>
    );
}

export default App;
